import {
    TableContainer,
    Table,
    TableHead,
    TableCell,
    Skeleton,
    TableBody,
    TableRow,
} from "@mui/material";
import { StyledTableHeader } from "./StyledTable";

interface Props {
    columns: number;
    rows: number;
}

const TableSkeleton = ({ columns, rows }: Props) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <StyledTableHeader>
                        {[...Array(columns)].map((_, index) => (
                            <TableCell key={`table-skeleton-header-${index}`}>
                                <Skeleton variant="text" />
                            </TableCell>
                        ))}
                    </StyledTableHeader>
                </TableHead>
                <TableBody>
                    {[...Array(rows)].map((_, index) => (
                        <TableRow key={`table-skeleton-row-${index}`}>
                            {[...Array(columns)].map(() => (
                                <TableCell
                                    key={`table-skeleton-row-cell-${index}`}
                                >
                                    <Skeleton variant="text" />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableSkeleton;
