export const CAPEX_CATEGORIES: string[] = [
    "TC y TT",
    "Medida",
    "Comuniación",
    "Inspección previa mano de obra",
    "Instalación mano de obra",
    "Costos administrativos",
    "Costos administrativos extraordinarios"
];

export const DEPARTMENTS = [
    "Antioquia",
    "Atlántico",
    "Bolívar",
    "Caldas",
    "Cesar",
    "Córdoba",
    "Cundinamarca",
    "Magdalena",
    "Risaralda",
    "Valle del Cauca",
];

export const MEASUREMENTS: string[] = [
    "Indirecta N2 dos elementos",
    "Indirecta N2 tres elementos",
    "Indirecta N3 dos elementos",
    "Indirecta N3 tres elementos",
    "Semidirecta N1 tres elementos",
    "Directa bifásica",
    "Directa monofásica",
    "Directa monofásica - Tipo riel",
    "Directa trifásica",
    "Directa trifásica - Tipo riel",
];

export const INSTALLATION_TYPES: string[] = ["Descentralizada", "Centralizada"];

export const MEASUREMENT_TYPES: string[] = [
    "Indirecta",
    "Semidirecta",
    "Directa",
];

export const EXCEL_HEADERS: string[] = [
    "Categoría de CAPEX",
    "Departamento",
    "IVA",
    "Medida",
    "TOTAL COP",
    "TOTAL FINAL COP + IVA",
    "TOTAL FINAL EN COP",
    "TOTAL USD",
    "TOTAL USD EN COP",
    "Tipo de Instalación",
    "Tipo Medida",
];
