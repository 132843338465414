import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import React from "react";

interface Props {
    onClick: () => void;
    icon?: React.ReactNode;
}

const Backbutton = ({ onClick, icon }: Props) => {
    return (
        <Button
            variant="outlined"
            color="primary"
            onClick={onClick}
            sx={{
                borderRadius: "50% !important",
                height: "41px !important",
                width: "41px !important",
                minWidth: "unset !important",
            }}
        >
            {icon ?? <ArrowBackIcon />}
        </Button>
    );
};

export default Backbutton;
