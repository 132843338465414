import { Button } from "@mui/material";
import { styled } from "@mui/system";
import {
    type Accept,
    type DropEvent,
    type FileRejection,
    useDropzone,
} from "react-dropzone";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import { enerbitColors } from "@enerbit/min-base";

type InputProps = {
    active: boolean;
    error?: boolean;
};

type Colors =
    | "success"
    | "info"
    | "warning"
    | "error"
    | "neutral"
    | "primary"
    | "secondary"
    | "inherit";

type Props = {
    onDrop: <T extends File>(
        acceptedFiles: T[],
        fileRejections: FileRejection[],
        event: DropEvent,
    ) => void;
    typeFile?: string;
    color?: Colors;
    error?: boolean;
    maxFiles?: number;
};

export const DragZone = ({
    onDrop,
    typeFile,
    // color,
    error,
    maxFiles,
}: Props) => {
    const acceptOptions = (): Accept => {
        const typeFileArray = typeFile?.split(",") ?? [];
        const acceptOptions: Accept = {};
        for (let i = 0; i < typeFileArray.length; i++) {
            switch (typeFileArray[i]) {
                case "img":
                    acceptOptions["image/*"] = [];
                    break;
                case "csv":
                    acceptOptions["text/csv"] = [".csv"];
                    break;
                case "xlsx":
                    acceptOptions["text/xlsx"] = [".xlsx"];
                    break;
                case "pdf":
                    acceptOptions["application/pdf"] = [".pdf"];
                    break;
                case "md":
                    acceptOptions["text/markdown"] = [".md"];
                    break;
                default:
                    break;
            }
        }
        return acceptOptions;
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: acceptOptions(),
        maxFiles,
    });
    const convertTypeFileToText = (typeFile: string) => {
        const typeFileArray = typeFile.split(",");
        if (typeFileArray.length > 1) {
            return "el/los archivos";
        }
        switch (typeFile) {
            case "img":
                return "Imagen";
            case "csv":
                return "Archivo";
            case "xlsx":
                return "Archivo";
            case "md":
                return "el archivo .md con la oferta ";
            case "pdf":
                return "pdf";
            default:
                return "el/los archivos";
        }
    };
    return (
        <FileUploader
            {...getRootProps()}
            active={isDragActive}
            error={error}
            sx={{ width: "100%" }}
        >
            Arrastra aquí o
            <Button
                sx={{ marginLeft: "10px", color: "#FFF" }}
                startIcon={<UploadOutlinedIcon />}
                variant="contained"
                color={"secondary"}
                component="label"
                onClick={(e) => e.stopPropagation()}
            >
                <input type="file" hidden {...getInputProps()} />
                Adjunta {typeFile ? convertTypeFileToText(typeFile) : "pdf"}
            </Button>
        </FileUploader>
    );
};

const FileUploader = styled("div")<InputProps>(({ active, error }) => ({
    alignItems: "center",
    backgroundColor: active ? "rgb(0, 0, 0, 0.1)" : "",
    border: `2px dashed ${error ? enerbitColors.error.main : "#CCC"}`,
    color: "#667085",
    cursor: "pointer",
    display: "flex",
    height: "100px",
    justifyContent: "center",
    margin: "10px 0",

    "&:hover": {
        backgroundColor: "rgb(0, 0, 0, 0.1)",
    },
}));
