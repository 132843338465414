import { Modal, Box, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import Backbutton from "./BackButton";
import { ObjectKeyValue } from "@enerbit/min-base/dist/common/models/customPropsComponents";

interface Props {
    onClose: () => void;
    open: boolean;
    children: React.ReactNode;
    title: string;
    styles?: ObjectKeyValue;
    disableClose?: boolean;
}

const modalStyles = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "800px",
    bgcolor: "background.paper",
    p: 3,
    borderRadius: "10px",
};

const StyledModal = ({
    onClose,
    open,
    children,
    title,
    styles,
    disableClose,
}: Props) => {
    return (
        <Modal open={open} onClose={disableClose ? () => {} : onClose}>
            <Box sx={{ ...modalStyles, ...styles }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        color={"primary"}
                        sx={{ fontSize: "21px", fontWeight: 700 }}
                    >
                        {title}
                    </Typography>
                    <Backbutton
                        onClick={disableClose ? () => {} : onClose}
                        icon={<Close />}
                    />
                </Box>
                <Box mt={2}>{children}</Box>
            </Box>
        </Modal>
    );
};

export default StyledModal;
