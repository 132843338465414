import { Box, IconButton, LinearProgress, Typography } from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { enerbitColors, EnerbitColors } from "@enerbit/min-base";

type Props = {
  fileInfo?: {
    name: string;
    size: number;
  };
  progress: number;
  isSuccess: boolean;
  onClearFile: () => void;
  typeFile?: string;
  bgColor?: keyof EnerbitColors; // Usar claves válidas de EnerbitColors
  downloadUrl?: string;
  isSeeFile?: boolean;
  isClearFile?: boolean;
};

export const CardPdf = ({
  bgColor,
  fileInfo,
  progress,
  isSuccess,
  onClearFile,
  typeFile,
  downloadUrl,
  isSeeFile,
  isClearFile,
}: Props) => {
  const downloadFile = () => {
    fetch(downloadUrl || "")
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileInfo?.name ?? "Archivo"}.md`);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
  };

  const { name, size } = fileInfo || {};

  const getResolvedBgColor = (bgColor: keyof EnerbitColors | undefined) => {
    if (!bgColor) return "#F04438"; // Color predeterminado
    const color = enerbitColors[bgColor];
    if ("main" in color) {
      return color.main;
    }
    return "#F04438"; // Color predeterminado si no tiene "main"
  };

  const resolvedBgColor = getResolvedBgColor(bgColor);

  return (
    <Box
      sx={{
        backgroundColor: "#F2F4F7",
        borderRadius: "16px",
        height: "82px",
        margin: "10px 0",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: resolvedBgColor,
          borderRadius: "4px",
          color: "#fff",
          display: "flex",
          fontSize: "12px",
          fontWeight: "bold",
          justifyContent: "center",
          height: "50px",
          marginRight: "16px",
          width: "50px",
        }}
      >
        {typeFile ? typeFile.toUpperCase() : "PDF"}
      </Box>
      <Box sx={{ width: "100%", textAlign: "start" }}>
        <Typography sx={{ fontWeight: "bold" }}>
          {name ? name : "Archivos pdf"}
        </Typography>
        {!isSuccess && typeFile !== "csv" ? (
          <LinearProgress
            sx={{ borderRadius: "5px", height: "8px", width: "100%" }}
            color="secondary"
            variant="determinate"
            value={progress}
          />
        ) : (
          <Typography sx={{ fontSize: "12px", color: "#667085" }}>
            {size ? `${size} bytes` : ""}
          </Typography>
        )}
      </Box>
      {isSeeFile && (
        <Box
          sx={{
            backgroundColor: enerbitColors.primary.main,
            alignItems: "center",
            borderRadius: "12px",
            display: "flex",
            height: "32px",
            justifyContent: "center",
            marginLeft: "10px",
            width: "32px",
            cursor: "pointer",
          }}
          onClick={downloadFile}
        >
          <IconButton sx={{ color: "white" }}>
            <VisibilityOutlinedIcon sx={{ fontSize: "22px" }} />
          </IconButton>
        </Box>
      )}
      {downloadUrl && downloadUrl !== "" && (
        <Box
          sx={{
            backgroundColor: enerbitColors.warning.main,
            alignItems: "center",
            borderRadius: "12px",
            display: "flex",
            height: "32px",
            justifyContent: "center",
            marginLeft: "10px",
            width: "32px",
            cursor: "pointer",
          }}
          onClick={downloadFile}
        >
          <IconButton sx={{ color: "white" }}>
            <DownloadOutlinedIcon sx={{ fontSize: "22px" }} />
          </IconButton>
        </Box>
      )}
      {isClearFile && (
        <Box
          sx={{
            backgroundColor: enerbitColors.error.main,
            alignItems: "center",
            borderRadius: "12px",
            display: "flex",
            height: "32px",
            justifyContent: "center",
            marginLeft: "10px",
            width: "32px",
            cursor: "pointer",
          }}
          onClick={onClearFile}
        >
          <IconButton sx={{ color: "white" }}>
            <DeleteOutlineOutlinedIcon sx={{ fontSize: "22px" }} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
