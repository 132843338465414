import { useState } from "react";
import StyledModal from "../common/StyledModal";
import { DropEvent, FileRejection } from "react-dropzone/.";
import { DragZone } from "../common/DragZone";
import { Alert, Box, Button } from "@mui/material";
import { CardPdf } from "../common/CardPdf";
import { uploadCostsFile } from "../../services";

interface Props {
    isOpen: boolean;
    handleClose: () => void;
}

const UploadCostsModal = ({ isOpen, handleClose }: Props) => {
    const [file, setFile] = useState<File | undefined>(undefined);
    const [error, setError] = useState<string | null>("");
    const [success, setSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const onClose = () => {
        setFile(undefined);
        setError(null);
        setSuccess(false);
        handleClose();
    };

    const onDrop = (
        acceptedFiles: File[],
        _fileRejections: FileRejection[],
        _event: DropEvent,
    ) => {
        setFile(acceptedFiles[0]);
    };

    const onClearFile = () => {
        setFile(undefined);
    };

    const saveFile = async () => {
        if (!file) {
            setError("Carga un archivo válido.");
            return;
        }

        setLoading(true);
        try {
            await uploadCostsFile(file);
            setSuccess(true);
        } catch (_error) {
            setError("Error cargando el archivo. Inténtalo de nuevo.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <StyledModal
            open={isOpen}
            onClose={onClose}
            title="Cargar costos y topes de instalación"
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                }}
            >
                {/* <Button
                    variant="text"
                    startIcon={<Download />}
                    color="secondary"
                >
                    Descargar plantilla
                </Button> */}
            </Box>
            {!file && <DragZone typeFile="xlsx,csv" onDrop={onDrop} />}
            {file && (
                <CardPdf
                    fileInfo={file}
                    onClearFile={onClearFile}
                    isSuccess={true}
                    progress={100}
                    typeFile={"xlsx"}
                    isClearFile={true}
                />
            )}
            {error && (
                <Alert severity="error" sx={{ my: 1 }}>
                    {error}
                </Alert>
            )}
            {success && (
                <Alert severity="success" sx={{ my: 1 }}>
                    Archivo cargado con éxito.
                </Alert>
            )}
            <Button
                sx={{ mt: 1 }}
                loading={loading}
                color="secondary"
                variant="contained"
                onClick={saveFile}
                fullWidth
            >
                Cargar
            </Button>
        </StyledModal>
    );
};

export default UploadCostsModal;
