import { Box, Button } from "@mui/material";
import { Upload } from "@mui/icons-material";
import { useState } from "react";
import UploadCostsModal from "../components/installation-costs/UploadCostsModal";
import CostsList from "../components/installation-costs/CostsList";

const InstallationCosts = () => {
    const [openCostsModal, setOpenCostsModal] = useState<boolean>(false);
    return (
        <>
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                    }}
                >
                    <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<Upload />}
                        onClick={() => setOpenCostsModal(true)}
                    >
                        Cargar costos
                    </Button>
                </Box>
                <CostsList />
            </Box>
            <UploadCostsModal
                isOpen={openCostsModal}
                handleClose={() => setOpenCostsModal(false)}
            />
        </>
    );
};

export default InstallationCosts;
