import { hot } from "react-hot-loader/root";
import {
    api,
    initEnvironment,
    PrivateRoute,
    ThemeConfig,
} from "@enerbit/min-base";
import InstallationCosts from "../src/pages/InstallationCosts";
import { SWRConfig } from "swr";

initEnvironment({ baseUrl: process.env.REACT_APP_BASE_URL });

const fetcher = (url: string) => api.get(url).then((res) => res.data);

export default hot(function Root() {
    return (
        <ThemeConfig>
            <PrivateRoute>
                <SWRConfig
                    value={{
                        fetcher,
                        keepPreviousData: true,
                    }}
                >
                    <InstallationCosts />
                </SWRConfig>
            </PrivateRoute>
        </ThemeConfig>
    );
});
