import { api } from "@enerbit/min-base";
import { Filters, CostsCeilingInstallation } from "../models";

export const fetchCosts = async (
    url: string,
    filters: Filters,
): Promise<CostsCeilingInstallation[]> => {
    const tFilters: Record<string, unknown> = {};

    Object.keys(filters).forEach((key) => {
        if (filters[key]) {
            tFilters[key] = filters[key];
        }
    });

    const params = { ...tFilters };

    const { data } = await api.get<CostsCeilingInstallation[]>(url, { params });

    return data;
};

export const uploadCostsFile = async (file: File) => {
    const formData = new FormData();

    formData.append("file", file);

    const res = await api.post<{ message: string; json_path: string }>(
        "/operational-cost/cost-ceiling-installation",
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        },
    );

    return res.data;
};
