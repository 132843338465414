import { Alert, Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { StyledDataGrid } from "../common/StyledDataGrid";
import { useState } from "react";
import { CostsCeilingInstallation, Filters } from "../../models";
import useSWR from "swr";
import { fetchCosts } from "../../services";
import TableSkeleton from "../common/TableSkeleton";
import { CustomPagination } from "../common";
import CostsListFilters from "./CostsListFilters";

const initialFilterValues: Filters = {
    category: "",
    department: "",
    measurement_type: "",
    installation_type: "",
    measurement: "",
};

const CostsList = () => {
    const [_currentPage, setCurrentPage] = useState<number>(0);
    const [filters, setFilters] = useState<Filters>(initialFilterValues);
    const [tempFilters, setTempFilters] = useState<Filters>(filters);
    const { data, error, isLoading, mutate } = useSWR<
        CostsCeilingInstallation[]
    >(
        ["/operational-cost/cost-ceiling-installation/search", filters],
        (args) => {
            const [url, filter] = args as [string, Filters];
            return fetchCosts(url, filter);
        },
    );

    const handleTempFiltersChange = (key: keyof Filters, value: unknown) => {
        setTempFilters((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const resetFilters = () => {
        setTempFilters(initialFilterValues);
        setFilters(initialFilterValues);
        setCurrentPage(0);
        mutate();
    };

    const handleSearch = () => {
        setFilters(tempFilters);
        setCurrentPage(0);
        mutate();
    };

    // const handleDownload = async () => {
    //     if (!data || data.length === 0) return;

    //     const workbook = new ExcelJS.Workbook();
    //     const worksheet = workbook.addWorksheet("Costos y topes");

    //     const columns = Object.keys(data[0]).map((header) => ({
    //         header,
    //         key: header,
    //     }));

    //     worksheet.columns = columns;

    //     data.forEach((item) => {
    //         worksheet.addRow(item);
    //     });

    //     const buffer = await workbook.xlsx.writeBuffer();
    //     const blob = new Blob([buffer], {
    //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //     });

    //     saveAs(blob, "precios_materiales_servicios");
    // };

    const columns: GridColDef[] = [
        {
            field: "Categoría de CAPEX",
            headerName: "Categoría de CAPEX",
            flex: 1,
        },
        {
            field: "Departamento",
            headerName: "Departamento",
            flex: 1,
        },
        {
            field: "Medida",
            headerName: "Medida",
            flex: 1,
        },
        {
            field: "Tipo Instalación",
            headerName: "Tipo Instalación",
            flex: 1,
        },
        {
            field: "Tipo Medida",
            headerName: "Tipo Medida",
            flex: 1,
        },
        {
            field: "IVA",
            headerName: "IVA",
            flex: 1,
        },
        {
            field: "TOTAL FINAL EN COP",
            headerName: "Total final en COP",
            flex: 1,
        },
        {
            field: "TOTAL FINAL COP + IVA",
            headerName: "Total final COP + IVA",
            flex: 1,
        },
    ];

    return (
        <Box>
            <Box sx={{ mt: 2 }}>
                <CostsListFilters
                    filters={tempFilters}
                    handleFiltersChange={handleTempFiltersChange}
                    resetFilters={resetFilters}
                    handleSearch={handleSearch}
                    // handleDownload={handleDownload}
                />
            </Box>
            {isLoading && (
                <Box sx={{ my: 3 }}>
                    <TableSkeleton columns={8} rows={8} />
                </Box>
            )}
            {error && (
                <Alert severity="error" sx={{ my: 3 }}>
                    Error listando costos.
                </Alert>
            )}
            {data && !isLoading && !error && (
                <Box sx={{ height: 600, width: "100%", my: 3, mb: 4 }}>
                    <StyledDataGrid
                        sx={{
                            "& .MuiDataGrid-virtualScrollerContent": {
                                height:
                                    data.length > 0
                                        ? "auto!important"
                                        : "100px!important",
                            },
                            "& .MuiDataGrid-row": {
                                minHeight: "52px!important",
                                py: 1,
                            },
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 10, page: 0 },
                            },
                        }}
                        getRowHeight={() => "auto"}
                        disableVirtualization
                        disableColumnMenu
                        rowSpacingType="border"
                        rows={data.map((d, index) => ({
                            ...d,
                            id: `costs-row-${index + 1}`,
                        }))}
                        getRowId={(row: any) => row.id}
                        rowHeight={100}
                        columns={columns}
                        autoHeight
                        pagination={undefined}
                        slots={{
                            pagination: CustomPagination,
                        }}
                        isRowSelectable={() => false}
                    />
                </Box>
            )}
        </Box>
    );
};

export default CostsList;
