import { styled, TextField } from "@mui/material";

const CustomTextField = styled(TextField)`
  border-radius: 12px;  
  & .MuiInputBase-root {
    height: 41px;
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-radius: 12px;
  }
`;

export default CustomTextField;
