import { Select, styled } from "@mui/material";

const CustomSelect = styled(Select)`
  border-radius: 12px;
  height: 43px;
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-radius: 12px;
  }
`;

export default CustomSelect;
