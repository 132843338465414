import { darken, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: "none",
    "& .MuiDataGrid-columnSeparator": {
        display: "none",
    },
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
        outline: "none !important",
    },
    "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
        {
            outline: "none !important",
        },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
        display: "block",
        position: "static",
    },
    "&>.MuiDataGrid-main": {
        "&>.MuiDataGrid-columnHeaders": {
            borderBottom: "none",
            backgroundColor: "#EFE9FB",
        },
    },
    ".MuiDataGrid-topContainer": {
        ".MuiDataGrid-columnHeader": {
            borderBottom: "none",
            backgroundColor: "#EFE9FB",
            ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 400,
            },
        },
    },
    "& .MuiDataGrid-cell": {
        border: "none",
        display: "flex",
        // justifyContent: "center",
        alignItems: "center",
    },
    "& .MuiDataGrid-row": {
        width: "100%",
        cursor: "pointer",
        border: "1px solid #D9D2E6",
        borderRadius: "8px",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    "& .MuiDataGrid-row.Mui-selected": {
        backgroundColor: "#EFE9FB",
        "&:hover": {
            backgroundColor: darken("#EFE9FB", 0.06),
        },
    },
    "& .MuiDataGrid-cell:focus": {
        outline: "none",
    },
    "& .MuiDataGrid-cell:focus-within": {
        outline: "none",
    },
    "& .MuiDataGrid-footerContainer": {
        justifyContent: "center",
        borderTop: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
        textOverflow: "inherit",
        fontWeight: "bold",
    },
    "& .MuiPaginationItem-root": {
        borderRadius: "8px",
    },
    "& .MuiTablePagination-root": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));
