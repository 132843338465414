import {
    Box,
    Button,
    IconButton,
    MenuItem,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { Cached, Search } from "@mui/icons-material";
import {
    CAPEX_CATEGORIES,
    DEPARTMENTS,
    INSTALLATION_TYPES,
    MEASUREMENT_TYPES,
    MEASUREMENTS,
} from "../../const";
import { Filters } from "../../models";
import CustomSelect from "../common/CustomSelect";

interface Props {
    filters: Filters;
    handleFiltersChange: (key: string, value: unknown) => void;
    resetFilters: () => void;
    handleSearch: () => void;
    // handleDownload: () => Promise<void>;
}

const CostsListFilters = ({
    handleSearch,
    handleFiltersChange,
    resetFilters,
    filters,
    // handleDownload,
}: Props) => {
    const isSmallScreen = useMediaQuery("lg");

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: isSmallScreen ? "column" : "row",
                alignItems: isSmallScreen ? "stretch" : "end",
                gap: 2,
                justifyContent: "space-between",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                    gap: 2,
                    flex: 1,
                }}
            >
                <Box sx={{ flex: 1 }}>
                    <CustomSelect
                        fullWidth
                        displayEmpty
                        defaultValue={""}
                        value={filters.category}
                        onChange={(e: any) => {
                            handleFiltersChange(
                                "category",
                                e.target.value as string,
                            );
                        }}
                    >
                        <MenuItem disabled value="">
                            <Typography sx={{ color: "rgba(0, 0, 0, 0.42)" }}>
                                Categoría de CAPEX
                            </Typography>
                        </MenuItem>
                        {CAPEX_CATEGORIES.map((item, index) => (
                            <MenuItem
                                key={`capex-category-${index + 1}`}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <CustomSelect
                        fullWidth
                        displayEmpty
                        defaultValue={""}
                        value={filters.department}
                        onChange={(e: any) =>
                            handleFiltersChange(
                                "department",
                                e.target.value as string,
                            )
                        }
                    >
                        <MenuItem disabled value="">
                            <Typography sx={{ color: "rgba(0, 0, 0, 0.42)" }}>
                                Departamento
                            </Typography>
                        </MenuItem>
                        {DEPARTMENTS.map((item, index) => (
                            <MenuItem
                                key={`department-${index + 1}`}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <CustomSelect
                        fullWidth
                        displayEmpty
                        defaultValue={""}
                        value={filters.measurement_type}
                        onChange={(e: any) =>
                            handleFiltersChange(
                                "measurement_type",
                                e.target.value as string,
                            )
                        }
                    >
                        <MenuItem disabled value="">
                            <Typography sx={{ color: "rgba(0, 0, 0, 0.42)" }}>
                                Tipo de medida
                            </Typography>
                        </MenuItem>
                        {MEASUREMENT_TYPES.map((item, index) => (
                            <MenuItem
                                key={`measurment-type-${index + 1}`}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <CustomSelect
                        fullWidth
                        displayEmpty
                        defaultValue={""}
                        value={filters.installation_type}
                        onChange={(e: any) =>
                            handleFiltersChange(
                                "installation_type",
                                e.target.value as string,
                            )
                        }
                    >
                        <MenuItem disabled value="">
                            <Typography sx={{ color: "rgba(0, 0, 0, 0.42)" }}>
                                Tipo de instalación
                            </Typography>
                        </MenuItem>
                        {INSTALLATION_TYPES.map((item, index) => (
                            <MenuItem
                                key={`installation-types-${index + 1}`}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <CustomSelect
                        fullWidth
                        displayEmpty
                        defaultValue={""}
                        value={filters.measurement}
                        onChange={(e: any) =>
                            handleFiltersChange(
                                "measurement",
                                e.target.value as string,
                            )
                        }
                    >
                        <MenuItem disabled value="">
                            <Typography sx={{ color: "rgba(0, 0, 0, 0.42)" }}>
                                Medida
                            </Typography>
                        </MenuItem>
                        {MEASUREMENTS.map((item, index) => (
                            <MenuItem
                                key={`measurement-${index + 1}`}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                    gap: 2,
                    alignItems: "center",
                    mt: isSmallScreen ? 2 : 0,
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                    fullWidth={isSmallScreen}
                >
                    <Search />
                </Button>
                {/* <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDownload}
                    fullWidth={isSmallScreen}
                >
                    <Download />
                </Button> */}
                <IconButton
                    color="primary"
                    onClick={resetFilters}
                    sx={{
                        width: isSmallScreen ? "100%" : "auto",
                        justifyContent: "center",
                    }}
                >
                    <Cached />
                </IconButton>
            </Box>
        </Box>
    );
};

export default CostsListFilters;
